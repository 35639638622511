const videoShader = {
  //默认无滤镜
  default: `
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  void main()
  {
      vec4 texColor = texture2D(ourTexture, TexCoord);
      gl_FragColor = texColor;
  }`,
  //分色偏移
  separationShift: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float u_offset;
  void main()
  {
      //原始采样像素的 RGBA 值
      vec4 textureColor = texture2D(ourTexture, TexCoord);
      if(textureColor.a < 0.1){
        discard;
      }
     //右下方偏移
      vec4 offsetColor0 = texture2D(ourTexture, vec2(TexCoord.x + u_offset, TexCoord.y + u_offset));
      //左上方偏移
      vec4 offsetColor1 = texture2D(ourTexture, vec2(TexCoord.x - u_offset, TexCoord.y - u_offset));

      //混合成一个颜色输出
      gl_FragColor = vec4(textureColor.r, offsetColor1.g, offsetColor0.b, textureColor.a);
  }`,
  //灵魂出窍
  outOfBody: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float u_offset;
  const float MAX_ALPHA = 0.5;
  const float MAX_SCALE = 0.8;
  void main()
  {
      //根据偏移量计算混合系数 alpha
      float alpha = MAX_ALPHA * (1.0 - u_offset);
      //根据偏移量计算混合系数 scale
      float scale = 1.0 + u_offset * MAX_SCALE;
      //缩放操作
      float scale_x = 0.5 + (TexCoord.x - 0.5) / scale;
      float scale_y = 0.5 + (TexCoord.y - 0.5) / scale;

      vec2 scaleCoord = vec2(scale_x, scale_y);

      vec4 maskColor = texture2D(ourTexture, scaleCoord);
      //原始采样像素的 RGBA 值
      vec4 originColor = texture2D(ourTexture, TexCoord);
      //加权混合
      gl_FragColor = originColor * (1.0 - alpha) + maskColor * alpha;
  }`,
  //毛刺
  burr: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float Time;
  // 随机数
  float rand(float n) {
      //fract(x)返回x的小数部分
      //返回 sin(n) * 43758.5453123
      //sin(n) * 极大值，带小数点，想要随机数算的比较低，乘的数就必须较大，噪声随机
      //如果想得到【0，1】范围的小数值，可以将sin * 1
      //如果只保留小数部分，乘以一个极大值
      return fract(sin(n) * 43758.5453123);
  }
  const float PI = 3.1415926;

  void main()
  {
      //最大抖动上限
      float maxJitter = 0.06;
      //一次毛刺效果的时长
      float duration = 0.3;
      //红色颜色偏移
      float colorROffset = 0.01;
      //绿色颜色偏移
      float colorBOffset = -0.025;

      //表示将传入的事件转换到一个周期内，范围是 0 ~ 0.6，抖动时长变成0.6
      float time = mod(Time, duration * 2.0);
      //振幅，随着时间变化，范围是[0, 1]
      float amplitude = max(sin(time * (PI / duration)), 0.0);

      //像素随机偏移范围 -1 ~ 1，* 2.0 - 1.0是为了得到【-1，1】范围内的随机值
      float jitter = rand(TexCoord.y) * 2.0 - 1.0;
      //判断是否需要偏移，如果jitter范围 < 最大范围*振幅
      // abs(jitter) 范围【0，1】
      // maxJitter * amplitude 范围【0， 0.06】
      bool needOffset = abs(jitter) < maxJitter * amplitude;

      //获取纹理x坐标，根据needOffset来计算它的x撕裂
      //needOffset = YES，则撕裂大
      //needOffset = NO，则撕裂小，需要降低撕裂 = *振幅*非常细微的数
      float textureX = TexCoord.x + (needOffset ? jitter : (jitter * amplitude * 0.006));
      //获取纹理撕裂后的x、y坐标
      vec2 textureCoords = vec2(textureX, TexCoord.y);

      //颜色偏移：获取3组颜色
      //撕裂后的原图颜色
      vec4 mask = texture2D(ourTexture, textureCoords);
      //根据撕裂计算后的纹理坐标，获取纹素
      vec4 maskR = texture2D(ourTexture, textureCoords + vec2(colorROffset * amplitude, 0.0));
      //根据撕裂计算后的纹理坐标，获取纹素
      vec4 maskB = texture2D(ourTexture, textureCoords + vec2(colorBOffset * amplitude, 0.0));

      //颜色主要撕裂，红色和蓝色部分，所以只调整红色
      gl_FragColor = vec4(maskR.r, mask.g, maskB.b, mask.a);
  }`,
  //幻觉
  illusion: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float Time;

  const float PI = 3.1415926;
  const float duration = 2.0;

  //这个函数可以计算出，在某个时刻图片的具体位置，通过它可以每经过一段时间，去生成一个新的mask
  //转圈产生幻影的单个像素点的颜色值
  vec4 getMask(float time, vec2 textureCoords, float padding) {
     //圆心坐标
      vec2 translation = vec2(sin(time * (PI * 2.0 / duration)),cos(time * (PI * 2.0 / duration)));

      //新的纹理坐标 = 原始纹理坐标 + 偏移量 * 圆周坐标（新的图层与图层之间是有间距的，所以需要偏移）
      vec2 translationTextureCoords = textureCoords + padding * translation;

      //根据新的纹理坐标获取新图层的纹素
      vec4 mask = texture2D(ourTexture, translationTextureCoords);
      if(mask.a < 0.1){
          mask.rgb = vec3(1., 1., 1.);
      }
      return mask;
  }

  //这个函数可以计算出，某个时刻创建的层，在当前时刻的透明度
  //进度：
  float maskAlphaProgress(float currentTime, float hideTime, float startTime) {
  //mod（时长+持续时间 - 开始时间，时长）得到一个周期内的time
      float time = mod(duration + currentTime - startTime, duration);
      //如果小于0.9，返回time，反之，返回0.9
      return min(time, hideTime);
  }

  void main()
  {
      //将传入的时间戳转换到一个周期内，time的范围是【0，2】
      //获得时间周期
      float time = mod(Time, duration);
      //放大后的倍数
      float scale = 1.2;
      //偏移量 = 0.083
      float padding = 0.5 * (1.0 - 1.0 / scale);
      //放大后的纹理坐标
      vec2 textureCoords = vec2(0.5, 0.5) + (TexCoord - vec2(0.5, 0.5)) / scale;

      //新建层的隐藏时间 即新建层什么时候隐藏
      float hideTime = 0.9;
      //时间间隔：隔0.2s创建一个新层
      const float timeGap = 0.2;

      //注意：只保留了红色的透明的通道值，因为幻觉效果残留红色
      //幻影残留数据
      //max RGB alpha
      //新图层的 R透明度
      float maxAlphaR = 0.5;
      //新图层的 G透明度
      float maxAlphaG = 0.05;
      //新图层的 B透明度
      float maxAlphaB = 0.05;

      //获取新的图层的坐标，需要传入时间、纹理坐标、偏移量
      vec4 mask = getMask(time, textureCoords, padding);
      //RGB ：for循环中使用
      float alphaR = 1.0;
      float alphaG = 1.0;
      float alphaB = 1.0;

      //最终图层颜色：初始化
      vec4 resultMask = vec4(0, 0, 0, 0);

      //循环：每一层循环都会得到新的图层的颜色，即幻影颜色
      //一次循环只是计算一个像素点的纹素，需要在真机运行。模拟器会卡，主要是模拟器上是CPU模拟GPU的
      for (float f = 0.0; f < duration; f += timeGap) {
          float tmpTime = f;
          //获取到【0，2】s内所获取的运动后的纹理坐标
          //获得幻影当前时间的颜色值
          vec4 tmpMask = getMask(tmpTime, textureCoords, padding);

          //某个时刻创建的层，在当前时刻的红绿蓝的透明度
          //临时的透明度 = 根据时间推移RGB的透明度发生变化
          //获得临时的红绿蓝透明度
          float tmpAlphaR = maxAlphaR - maxAlphaR * maskAlphaProgress(time, hideTime, tmpTime) / hideTime;
          float tmpAlphaG = maxAlphaG - maxAlphaG * maskAlphaProgress(time, hideTime, tmpTime) / hideTime;
          float tmpAlphaB = maxAlphaB - maxAlphaB * maskAlphaProgress(time, hideTime, tmpTime) / hideTime;

          //累计每一层临时RGB * RGB的临时透明度
          //结果 += 临时颜色 * 透明度，即刚产生的图层的颜色
          resultMask += vec4(tmpMask.r * tmpAlphaR,tmpMask.g * tmpAlphaG,tmpMask.b * tmpAlphaB,1.0);

          //透明度递减
          alphaR -= tmpAlphaR;
          alphaG -= tmpAlphaG;
          alphaB -= tmpAlphaB;
      }

      //最终颜色 += 原始纹理的RGB * 透明度
      resultMask += vec4(mask.r * alphaR, mask.g * alphaG, mask.b * alphaB, 1.0);

      //将最终颜色填充到像素点里
      gl_FragColor = resultMask;
  }`,
  //闪烁星光
  starBulinbulin: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float Time;

  float PI = 3.1415;
  float MIN_DIVIDE = 64.0;
  float MAX_DIVIDE = 0.01;
  #define STARFIELD_LAYERS_COUNT 12.0

  mat2 Rotate(float angle) {
      float s = sin(angle);
      float c = cos(angle);
      return mat2(c, -s, s, c);
  }

  float Star(vec2 uv, float flaresize, float rotAngle, float randomN) {
      float d = length(uv);

      float starcore = 0.05/d;
      uv *= Rotate(-2.0 * PI * rotAngle);
      float flareMax = 1.0;

      float starflares = max(0.0, flareMax - abs(uv.x * uv.y * 3000.0));
      starcore += starflares * flaresize;
      uv *= Rotate(PI * 0.25);

      starflares = max(0.0, flareMax - abs(uv.x * uv.y * 3000.0));
      starcore += starflares * 0.3 * flaresize;
      starcore *= smoothstep(1.0, 0.05, d);

      return starcore;
  }

  float PseudoRandomizer(vec2 p) {
      p = fract(p*vec2(123.45, 345.67));
      p += dot(p, p+45.32);

      return (fract(p.x * p.y));
  }

  vec3 StarFieldLayer(vec2 uv, float rotAngle) {
      vec3 col = vec3(0);
      vec2 gv = fract(uv) -0.5;
      vec2 id = floor(uv);

      float deltaTimeTwinkle = Time * 0.35;

      for (int y = -1; y <= 1; y++) {
          for (int x = -1; x <= 1; x++) {
              vec2 offset = vec2(x, y);

              float randomN = PseudoRandomizer(id + offset);
              float randoX = randomN - 0.5;
              float randoY = fract(randomN * 45.0) - 0.5;
              vec2 randomPosition = gv - offset - vec2(randoX, randoY);

              float size = fract(randomN * 1356.33);
              float flareSwitch = smoothstep(0.9, 1.0, size);

              float star = Star(randomPosition, flareSwitch, rotAngle, randomN);

              float randomStarColorSeed = fract(randomN * 2150.0) * (3.0 * PI) * deltaTimeTwinkle;
              vec3 color = sin(vec3(0.7, 0.3, 0.9) * randomStarColorSeed);

              color = color * (0.6 * sin(deltaTimeTwinkle)) + 0.4;

              color = color * vec3(1, 0.1,  0.9 + size);
              float dimByDensity = 15.0/STARFIELD_LAYERS_COUNT;
              col += star * size * color * dimByDensity;
           }
      }
      return col;
  }

  void main() {
      vec2 tuv = TexCoord.xy;
      gl_FragColor = texture2D(ourTexture, tuv);
      if(gl_FragColor.a < 0.1){
        discard;
      }
      float deltaTime = Time * 0.01;
      vec3 col = vec3(0.0);
      float rotAngle = deltaTime * 0.09;

      for (float i=0.0; i < 1.0; i += (1.0/STARFIELD_LAYERS_COUNT)) {
          float layerDepth = fract(i + deltaTime);
          float layerScale = mix(MIN_DIVIDE,MAX_DIVIDE,layerDepth);
          float layerFader = layerDepth * smoothstep(0.1, 1.1, layerDepth);
          float layerOffset = i * (3430.00 + fract(i));
          mat2 layerRot = Rotate(rotAngle * i * -10.0);
          tuv *= layerRot;
          vec2 starfieldUv = tuv * layerScale + layerOffset;
          col += StarFieldLayer(starfieldUv, rotAngle) * layerFader;
      }
      gl_FragColor += vec4(col, 1.0);
  }`,
  //缩放
  scale: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float Time;
  const float PI = 3.1415926;
  
  void main(){
      // 缩放时间周期
      float duration = 0.6;
      //缩放增加的最大值
      float maxAmplitude = 0.3;
      //缩放中心点
      vec2 anchorPoint = vec2(0.5, 0.5);
      //根据当前时间进度计算缩放幅度
      float time = mod(Time, duration);
      float amplitude = 1.0 + maxAmplitude * abs(sin(time * (PI / duration)));
      //转化纹理坐标变化
      vec2 textCoords = TexCoord;
      textCoords = vec2(anchorPoint.x + (textCoords.x - anchorPoint.x) / amplitude, anchorPoint.y + (textCoords.y - anchorPoint.y) / amplitude);
      //
      vec4 mask = texture2D(ourTexture, textCoords);
      if(mask.a < 0.1){
        discard;
      }
      gl_FragColor = vec4(mask.rgb, 1.0);
  }`,
  //旋转图
  spinningRound: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float u_Offset;
  uniform vec2 u_Screen_Size;

  const float PI = 3.141592653;
  void main()
  {
      //纹理坐标转为图片坐标
      vec2 imgTex = TexCoord * u_Screen_Size;
      float r = 0.4 * u_Screen_Size.x; //设置半径为图片宽度的 0.4 倍
      //取圆心为中心点
      if(distance(imgTex, vec2(u_Screen_Size.x / 2.0, u_Screen_Size.y / 2.0)) < r)
      {
          vec2 tranTex = TexCoord - 0.5;
          vec2 imgTranTex = tranTex * u_Screen_Size;
          float len = length(imgTranTex);
          float angle = 0.0;

          angle = acos(imgTranTex.x / len);

          if(tranTex.y < 0.0)
          {
              angle *= -1.0;
          }

          angle -= u_Offset;

          imgTranTex.x = len * cos(angle);
          imgTranTex.y = len * sin(angle);

          vec2 newTexCoors = imgTranTex / u_Screen_Size + 0.5;

          gl_FragColor = texture2D(ourTexture, newTexCoors);
      }
      else
      {
          gl_FragColor = texture2D(ourTexture, TexCoord);
      }

  }`,
  //抖动偏移
  jitterOffset: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float Time;
  const float PI = 3.141592653;
  void main()
  {
      //一次抖动效果的时长
      float duration = 2.0;
      //放大图片的上限
      float maxScale = 1.1;
      //颜色偏移的步长
      float offset = 0.01;

      //进度 0 ~ 1
      float progress = mod(Time, duration) / duration;
      //颜色偏移值0 ~ 0.02
      vec2 offsetCoords = vec2(offset, offset) * progress;
      //缩放比例 1.0 ~ 1.1
      float scale = 1.0 + (maxScale - 1.0) * progress;

      //放大后的纹理坐标
      //下面这种向量相加减的方式 等价于 灵魂出窍滤镜中的单个计算x、y坐标再组合的为纹理坐标的方式
      vec2 ScaleTextureCoords = vec2(0.5, 0.5) + (TexCoord - vec2(0.5, 0.5)) / scale;

      //获取三组颜色：颜色偏移计算可以随意，只要偏移量很小即可
      //原始颜色 + offset
      vec4 maskR = texture2D(ourTexture, ScaleTextureCoords + offsetCoords);
      //原始颜色 - offset
      vec4 maskB = texture2D(ourTexture, ScaleTextureCoords - offsetCoords);
      //原始颜色
      vec4 mask = texture2D(ourTexture, ScaleTextureCoords);

      //从3组颜色中分别取出 红色R，绿色G，蓝色B，透明度A填充到内置变量gl_FragColor内
      gl_FragColor = vec4(maskR.r, maskB.g, mask.b, mask.a);
  }`,
  //雨点涟漪
  ripplesInRain: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform vec2 u_Screen_Size;
  uniform float Time;
  // Maximum number of cells a ripple can cross.
  #define MAX_RADIUS 2
  // Set to 1 to hash twice. Slower, but less patterns.
  #define DOUBLE_HASH 0
  // Hash functions shamefully stolen from:
  // https://www.shadertoy.com/view/4djSRW
  #define HASHSCALE1 .1031
  #define HASHSCALE3 vec3(.1031, .1030, .0973)
  float hash12(vec2 p)
  {
      vec3 p3  = fract(vec3(p.xyx) * HASHSCALE1);
      p3 += dot(p3, p3.yzx + 19.19);
      return fract((p3.x + p3.y) * p3.z);
  }
  vec2 hash22(vec2 p)
  {
      vec3 p3 = fract(vec3(p.xyx) * HASHSCALE3);
      p3 += dot(p3, p3.yzx+19.19);
      return fract((p3.xx+p3.yz)*p3.zy);
  }
  void main()
  {
      vec2 TexCoord = TexCoord * u_Screen_Size.xy;
      float resolution = 10. * exp2(-3.*0.5/u_Screen_Size.x);
      vec2 uv = TexCoord.xy / u_Screen_Size.xy * resolution;
      vec2 p0 = floor(uv);
      vec2 circles = vec2(0.);
      for (int j = -MAX_RADIUS; j <= MAX_RADIUS; ++j)
      {
          for (int i = -MAX_RADIUS; i <= MAX_RADIUS; ++i)
          {
              vec2 pi = p0 + vec2(i, j);
              #if DOUBLE_HASH
              vec2 hsh = hash22(pi);
              #else
              vec2 hsh = pi;
              #endif
              vec2 p = pi + hash22(hsh);
              float t = fract(0.2*Time + hash12(hsh));
              vec2 v = p - uv;
              float d = length(v) - (float(MAX_RADIUS) + 1.)*t;
              float h = 1e-3;
              float d1 = d - h;
              float d2 = d + h;
              float p1 = sin(31.*d1) * smoothstep(-0.6, -0.3, d1) * smoothstep(0., -0.3, d1);
              float p2 = sin(31.*d2) * smoothstep(-0.6, -0.3, d2) * smoothstep(0., -0.3, d2);
              circles += 0.5 * normalize(v) * ((p2 - p1) / (2. * h) * (1. - t) * (1. - t) * (1. - t));
          }
      }
      circles /= float((MAX_RADIUS*2+1)*(MAX_RADIUS*2+1));
      float intensity = mix(0.01, 0.15, smoothstep(0.1, 0.6, abs(fract(0.05*Time + 0.5)*2.-1.)));
      vec3 n = vec3(circles, sqrt(1. - dot(circles, circles)));
      vec4 color = texture2D(ourTexture, uv/resolution - intensity*n.xy) + 5.*pow(clamp(dot(n, normalize(vec3(1., 0.7, 0.5))), 0., 1.), 6.);
      if(color.a < 0.1){
          discard;
      }
      gl_FragColor = color;
  }`,
  //果冻律动
  jellyRhythm: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float Time;
  void main()
  {
      vec2 uv = TexCoord.xy;
      float scale = 5.0;
      //划分网格
      vec2 id = floor(uv * scale);
      //不同网格错开不同的偏移
      vec2 offs = vec2(sin(uv.y * scale + sin(Time * 3.0) * 20.0 + id.y * 0.2), sin(uv.x * scale + sin(Time * 3.0) * 20.0 + id.x * 0.2));
      offs *= 0.01;
      uv.xy += offs ;
      gl_FragColor = texture2D(ourTexture, uv);
  }`,
  //烟雾
  smoke: ` //浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float Time;
  #define TAU 6.28318530718

  const int MAX_ITER = 5; //in {0, 20}
  float inten = 0.005; //in {0.000, 0.010}
  float OFF = 250.0; //in {0.0, 300.0}
  void main()
  {
      float time = Time * 0.2 + 23.0;
      vec2 uv = TexCoord;
      vec2 p = mod(uv * TAU, TAU) - vec2(OFF);
      vec2 i = vec2(p);
      float c = 0.8;

      //波形的迭代叠加
      for (int n = 0; n < MAX_ITER; n++)
      {
          float t = time * (1.0 - (3.5 / float(n + 1)));

          //更新 i，坐标位置的波形偏移（扭曲）
          i = p + vec2(cos(t - i.x) + sin(t + i.y),
                       sin(t - i.y) + cos(t + i.x));

          //“圆域波形扭曲”光线的累加
          c += 1.0 / length(vec2(p.x / (sin(i.x + t) / inten),
                                 p.y / (cos(i.y + t) / inten)));
      }

      c /= float(MAX_ITER);
      // c = clamp(c, 0., 1.);
      c = 1.17 - pow(c, 1.4);
      c = pow(abs(c), 8.0);
      vec3 colour = vec3(c);
      gl_FragColor = mix(texture2D(ourTexture, TexCoord), vec4(clamp(colour, 0., 1.), 1.0), 0.3);
  }`,
  //动态网格
  dynamicGrid: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float u_offset;
  uniform vec2 u_Screen_Size;
  void main()
  {
      vec2 imgTexCoord = TexCoord * u_Screen_Size;
      float sideLength = u_Screen_Size.y /6.0;
      float maxoffset = 0.15 * sideLength;
      float x = mod(imgTexCoord.x, floor(sideLength));
      float y = mod(imgTexCoord.y, floor(sideLength));
      float offset = u_offset * maxoffset;
      if(offset <= x && x <= sideLength - offset && offset <= y && y <= sideLength - offset){
          gl_FragColor =  texture2D(ourTexture, TexCoord);
      }
      else{
          gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
      }
  }`,
  //黑白终场
  blackAndWhiteFinale: `
  //浮点数设置为中等精度
  //浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float uTime;
  
  void main() {
      gl_FragColor = texture2D(ourTexture, TexCoord);
      float usePts = max(uTime, 0.7);
      float gray = (gl_FragColor.r + gl_FragColor.g + gl_FragColor.b) / (usePts * usePts);
      gl_FragColor.r = gray;
      gl_FragColor.g = gray;
      gl_FragColor.b = gray;
  }`,
  //闪白
  flashOfWhite: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform float Time;
  const float PI = 3.1415926;

  float rand(float n) {
      return fract(sin(n) * 43758.5453123);
  }

  void main() {
      float duration = 1.0;
      float progress = mod(Time, duration) / duration; // 0~1
      vec4 whiteMask = vec4(1.0, 1.0, 1.0, 1.0);
      float amplitude = abs(sin(progress * (PI / duration)));

      vec4 mask = texture2D(ourTexture, TexCoord);
      gl_FragColor = mask * (1.0 - amplitude) + whiteMask * amplitude;
  }`,
  //色感变化
  senseOfColorChange: `
  //浮点数设置为中等精度
                precision mediump float;
                varying vec2 TexCoord;
                uniform sampler2D ourTexture;
                uniform float Time;
                const float PI = 3.1415926;

                float rand(float n) {
                    return fract(sin(n) * 43758.5453123);
                }

                void main() {
                    float duration = 0.8;
                    float progressR = mod(Time - 1.0, duration) / duration; // 0~1
                    float progressG = mod(Time, duration) / duration; // 0~1
                    float progressB = mod(Time + 1.0, duration) / duration; // 0~1

                    gl_FragColor = texture2D(ourTexture, TexCoord);
                    gl_FragColor.r = gl_FragColor.r * progressR;
                    gl_FragColor.g = gl_FragColor.g * progressG;
                    gl_FragColor.b = gl_FragColor.b * progressB;
  }`,
  //太阳耀斑
  sun: `
  //太阳耀斑
                            //浮点数设置为中等精度
                              precision mediump float;
                              varying vec2 TexCoord;
                              uniform sampler2D ourTexture;
                              uniform vec2 u_Screen_Size;
                              uniform float Time;
                              float noise(float t)
                            {
                                return texture2D(ourTexture,vec2(t,.0)/vec2(64.0,64.0)).x;
                            }
                            float noise2D(vec2 t)
                            {
                                return texture2D(ourTexture,t/vec2(64.0,64.0)).x;
                            }

                            vec3 lensflare(vec2 uv,vec2 pos)
                            {
                                vec2 main = uv-pos;
                                vec2 uvd = uv*(length(uv));

                                float ang = atan(main.x,main.y);
                                float dist=length(main); dist = pow(dist,.1);
                                float n = noise2D(vec2(ang*16.0,dist*32.0));

                                float f0 = 1.0/(length(uv-pos)*16.0+1.0);

                                f0 = f0 + f0*(sin(noise(sin(ang*2.+pos.x)*4.0 - cos(ang*3.+pos.y))*16.)*.1 + dist*.1 + .8);

                                float f1 = max(0.01-pow(length(uv+1.2*pos),1.9),.0)*7.0;

                                float f2 = max(1.0/(1.0+32.0*pow(length(uvd+0.8*pos),2.0)),.0)*00.25;
                                float f22 = max(1.0/(1.0+32.0*pow(length(uvd+0.85*pos),2.0)),.0)*00.23;
                                float f23 = max(1.0/(1.0+32.0*pow(length(uvd+0.9*pos),2.0)),.0)*00.21;

                                vec2 uvx = mix(uv,uvd,-0.5);

                                float f4 = max(0.01-pow(length(uvx+0.4*pos),2.4),.0)*6.0;
                                float f42 = max(0.01-pow(length(uvx+0.45*pos),2.4),.0)*5.0;
                                float f43 = max(0.01-pow(length(uvx+0.5*pos),2.4),.0)*3.0;

                                uvx = mix(uv,uvd,-.4);

                                float f5 = max(0.01-pow(length(uvx+0.2*pos),5.5),.0)*2.0;
                                float f52 = max(0.01-pow(length(uvx+0.4*pos),5.5),.0)*2.0;
                                float f53 = max(0.01-pow(length(uvx+0.6*pos),5.5),.0)*2.0;

                                uvx = mix(uv,uvd,-0.5);

                                float f6 = max(0.01-pow(length(uvx-0.3*pos),1.6),.0)*6.0;
                                float f62 = max(0.01-pow(length(uvx-0.325*pos),1.6),.0)*3.0;
                                float f63 = max(0.01-pow(length(uvx-0.35*pos),1.6),.0)*5.0;

                                vec3 c = vec3(.0);

                                c.r+=f2+f4+f5+f6; c.g+=f22+f42+f52+f62; c.b+=f23+f43+f53+f63;
                                c = c*1.3 - vec3(length(uvd)*.05);
                                c+=vec3(f0);

                                return c;
                            }

                            vec3 cc(vec3 color, float factor,float factor2) // color modifier
                            {
                                float w = color.x+color.y+color.z;
                                return mix(color,vec3(w)*factor,w*factor2);
                            }

                            void main()
                            {
                                vec2 uv = TexCoord.xy - 0.5;
                                uv.x *= u_Screen_Size.x/u_Screen_Size.y; //fix aspect ratio
                                vec2 mouse;
                                mouse.x *= u_Screen_Size.x/u_Screen_Size.y; //fix aspect ratio
                                mouse.y=sin(Time)*.5;
                                mouse.x=sin(Time*.913)*.5;
                                vec3 color = vec3(1.4,1.2,1.0)*lensflare(uv,mouse.xy);
                                color -= noise2D(gl_FragCoord.xy)*.015;
                                color = cc(color,.5,.1);
                                gl_FragColor = mix(vec4(color,1.0),texture2D(ourTexture, TexCoord), 0.3);
                            }`,
  //动态模糊
  motionBlur: `
                             //动态模糊
                            //浮点数设置为中等精度
                            precision mediump float;
                            varying vec2 TexCoord;
                            uniform sampler2D ourTexture;
                            uniform vec2 u_Screen_Size;
                            uniform float Time;
                            #define GOLDEN_ANGLE 2.3999632
                            #define ITERATIONS 150
                            mat2 rot = mat2(cos(GOLDEN_ANGLE), sin(GOLDEN_ANGLE), -sin(GOLDEN_ANGLE), cos(GOLDEN_ANGLE));
                            vec3 Bokeh(sampler2D tex, vec2 uv, float radius)
                            {
                            vec3 acc = vec3(0), div = acc;
                            float r = 1.;
                            vec2 vangle = vec2(0.0,radius*.01 / sqrt(float(ITERATIONS)));
                            for (int j = 0; j < ITERATIONS; j++)
                            {
                                // the approx increase in the scale of sqrt(0, 1, 2, 3...)
                                r += 1. / r;
                                vangle = rot * vangle;
                                vec4 col = texture2D(tex, uv + (r-1.) * vangle); /// ... Sample the image
                                if(col.a < 0.1){
                                  col.rgb= vec3(1.,1.,1.);
                                }
                                col = col * col *1.8; // ... Contrast it for better highlights - leave this out elsewhere.
                                vec3 bokeh = pow(col.rgb, vec3(4));
                                acc += col.rgb * bokeh;
                                div += bokeh;
                                }
                                return acc / div;
                            }
                            void main()
                            {
                                vec2 TexCoord = TexCoord * u_Screen_Size;
                                vec2 uv = TexCoord / u_Screen_Size.xy; //... with correct aspect ratio
                                float time = mod(Time*.2 +.25, 3.0);
                                float rad = .8 - .8*cos(time * 6.283);
                                gl_FragColor = vec4(Bokeh(ourTexture, uv, rad), 1.0);
                            }`,
  //水滴滑落
  waterDropletsFall: ` //水滴滑落
  precision highp float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform vec2 u_Screen_Size;
  uniform float Time;
  #define S(a, b, t) smoothstep(a, b, t)
  #define USE_POST_PROCESSING

  vec3 N13(float p) {
      //  from DAVE HOSKINS
     vec3 p3 = fract(vec3(p) * vec3(.1031,.11369,.13787));
     p3 += dot(p3, p3.yzx + 19.19);
     return fract(vec3((p3.x + p3.y)*p3.z, (p3.x+p3.z)*p3.y, (p3.y+p3.z)*p3.x));
  }

  vec4 N14(float t) {
      return fract(sin(t*vec4(123., 1024., 1456., 264.))*vec4(6547., 345., 8799., 1564.));
  }
  float N(float t) {
      return fract(sin(t*12345.564)*7658.76);
  }

  float Saw(float b, float t) {
      return S(0., b, t)*S(1., b, t);
  }


  vec2 DropLayer2(vec2 uv, float t) {
      vec2 UV = uv;

      uv.y += t*0.75;
      vec2 a = vec2(6., 1.);
      vec2 grid = a*2.;
      vec2 id = floor(uv*grid);

      float colShift = N(id.x);
      uv.y += colShift;

      id = floor(uv*grid);
      vec3 n = N13(id.x*35.2+id.y*2376.1);
      vec2 st = fract(uv*grid)-vec2(.5, 0);

      float x = n.x-.5;

      float y = UV.y*20.;
      float wiggle = sin(y+sin(y));
      x += wiggle*(.5-abs(x))*(n.z-.5);
      x *= .7;
      float ti = fract(t+n.z);
      y = (Saw(.85, ti)-.5)*.9+.5;
      vec2 p = vec2(x, y);

      float d = length((st-p)*a.yx);

      float mainDrop = S(.4, .0, d);

      float r = sqrt(S(1., y, st.y));
      float cd = abs(st.x-x);
      float trail = S(.23*r, .15*r*r, cd);
      float trailFront = S(-.02, .02, st.y-y);
      trail *= trailFront*r*r;

      y = UV.y;
      float trail2 = S(.2*r, .0, cd);
      float droplets = max(0., (sin(y*(1.-y)*120.)-st.y))*trail2*trailFront*n.z;
      y = fract(y*10.)+(st.y-.5);
      float dd = length(st-vec2(x, y));
      droplets = S(.3, 0., dd);
      float m = mainDrop+droplets*r*trailFront;

      //m += st.x>a.y*.45 || st.y>a.x*.165 ? 1.2 : 0.;
      return vec2(m, trail);
  }

  float StaticDrops(vec2 uv, float t) {
      uv *= 40.;

      vec2 id = floor(uv);
      uv = fract(uv)-.5;
      vec3 n = N13(id.x*107.45+id.y*3543.654);
      vec2 p = (n.xy-.5)*.7;
      float d = length(uv-p);

      float fade = Saw(.025, fract(t+n.z));
      float c = S(.3, 0., d)*fract(n.z*10.)*fade;
      return c;
  }

  vec2 Drops(vec2 uv, float t, float l0, float l1, float l2) {
      float s = StaticDrops(uv, t)*l0;
      vec2 m1 = DropLayer2(uv, t)*l1;
      vec2 m2 = DropLayer2(uv*1.85, t)*l2;

      float c = s+m1.x+m2.x;
      c = S(.3, 1., c);

      return vec2(c, max(m1.y*l0, m2.y*l1));
  }

  void main()
  {
      vec2 TexCoord = TexCoord * u_Screen_Size;
      vec2 uv = (TexCoord.xy-.5*u_Screen_Size.xy) / u_Screen_Size.y;
      vec2 UV = TexCoord.xy/u_Screen_Size.xy;
      float T = Time;

      float t = T*.2;

      float rainAmount = sin(T*.05)*.3+.7;

      float maxBlur = mix(3., 6., rainAmount);
      float minBlur = 2.;

      float story = 0.;
      float heart = 0.;

      float zoom = 1.;
      uv *= .7+zoom*.3;

      UV = (UV-.5)*(.9+zoom*.1)+.5;

      float staticDrops = S(-.5, 1., rainAmount)*2.;
      float layer1 = S(.25, .75, rainAmount);
      float layer2 = S(.0, .5, rainAmount);


      vec2 c = Drops(uv, t, staticDrops, layer1, layer2);
     #ifdef CHEAP_NORMALS
          vec2 n = vec2(dFdx(c.x), dFdy(c.x));// cheap normals (3x cheaper, but 2 times shittier ;))
      #else
          vec2 e = vec2(.001, 0.);
          float cx = Drops(uv+e, t, staticDrops, layer1, layer2).x;
          float cy = Drops(uv+e.yx, t, staticDrops, layer1, layer2).x;
          vec2 n = vec2(cx-c.x, cy-c.x);		// expensive normals
      #endif

      float focus = mix(maxBlur-c.y, minBlur, S(.1, .2, c.x));
      vec4 col = texture2D(ourTexture, UV+n, 0.6 * focus).rgba;
      if(col.a < 0.1){
        discard;
      }

      #ifdef USE_POST_PROCESSING
      t = (T+3.)*.5;										// make time sync with first lightnoing
      col.rgb *= mix(vec3(1.), vec3(.8, .9, 1.3), 0.5);	// subtle col.rgbor shift
      float lightning = sin(t*sin(t*10.));				// lighting flicker
      lightning *= pow(max(0., sin(t+sin(t))), 10.);		// lightning flash
      col.rgb *= 1.+lightning*mix(1., .1, story*story);	// composite lightning
      col.rgb *= 1.-dot(UV-=.5, UV);							// vignette

      #endif

      //col.rgb = vec3(heart);
      gl_FragColor = vec4(col.rgb, 1.);
  }`,
  //冬雪
  winterSnow: `  //浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform vec2 u_Screen_Size;
  uniform float Time;
  #define mod289(x) mod(x, 289.)
  vec3 permute(vec3 x) {
      return mod289(((x*34.0)+1.0)*x);
  }
  
  float snoise(vec2 v)
  {
      const vec4 C = vec4(0.211324865405187,0.366025403784439,-0.577350269189626,0.024390243902439);
      vec2 i  = floor(v + dot(v, C.yy) );
      vec2 x0 = v -   i + dot(i, C.xx);
  
      vec2 i1;
      i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
      vec4 x12 = x0.xyxy + C.xxzz;
      x12.xy -= i1;
  
      i = mod289(i); // Avoid truncation effects in permutation
      vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
                        + i.x + vec3(0.0, i1.x, 1.0 ));
  
      vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
      m = m*m ;
      m = m*m ;
  
      vec3 x = 2.0 * fract(p * C.www) - 1.0;
      vec3 h = abs(x) - 0.5;
      vec3 ox = floor(x + 0.5);
      vec3 a0 = x - ox;
  
      m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
  
      vec3 g;
      g.x  = a0.x  * x0.x  + h.x  * x0.y;
      g.yz = a0.yz * x12.xz + h.yz * x12.yw;
  
      return 130.0 * dot(m, g);
  }
  float fbm(vec2 p)
  {
      float f = 0.0;
      float w = 0.5;
      for (int i = 0; i < 5; i ++)
      {
          f += w * snoise(p);
          p *= 2.;
          w *= 0.5;
      }
      return f;
  }
  
  #define LAYERS 66
  #define DEPTH1 .3
  #define WIDTH1 .4
  #define SPEED1 .6
  #define DEPTH2 .1
  #define WIDTH2 .3
  #define SPEED2 .1
  
  float snowing(in vec2 uv, in vec2 fragCoord )
  {
      const mat3 p = mat3(13.323122,23.5112,21.71123,21.1212,28.7312,11.9312,21.8112,14.7212,61.3934);
      vec2 mp = vec2(1.0, 1.0) / u_Screen_Size.xy;
      uv.x += mp.x*4.0;
      mp.y *= 0.25;
      float depth = smoothstep(DEPTH1, DEPTH2, mp.y);
      float width = smoothstep(WIDTH1, WIDTH2, mp.y);
      float speed = smoothstep(SPEED1, SPEED2, mp.y);
      float acc = 0.0;
      float dof = 5.0 * sin(Time * 0.1);
      for (int i=0; i < LAYERS; i++)
      {
          float fi = float(i);
          vec2 q = uv * (1.0 + fi*depth);
          float w = width * mod(fi*7.238917,1.0)-width*0.1*sin(Time*2.+fi);
          q += vec2(q.y*w, speed*Time / (1.0+fi*depth*0.03));
          vec3 n = vec3(floor(q),31.189+fi);
          vec3 m = floor(n)*0.00001 + fract(n);
          vec3 mp = (31415.9+m) / fract(p*m);
          vec3 r = fract(mp);
          vec2 s = abs(mod(q,1.0) -0.5 +0.9*r.xy -0.45);
          s += 0.01*abs(2.0*fract(10.*q.yx)-1.);
          float d = 0.6*max(s.x-s.y,s.x+s.y)+max(s.x,s.y)-.01;
          float edge = 0.05 +0.05*min(.5*abs(fi-5.-dof),1.);
          acc += smoothstep(edge,-edge,d)*(r.x/(1.+.02*fi*depth));
      }
      return acc;
  }
  
  //-----------------------------------------------------
  void main()
  {
      vec2 TexCoord = TexCoord * u_Screen_Size;
      vec2 uv = TexCoord.xy / u_Screen_Size.xy;
      gl_FragColor = texture2D(ourTexture, uv);
      float snowOut = snowing(uv, TexCoord);
      gl_FragColor += vec4(vec3(snowOut), 1.0);
  }`,
  //sakura
  sakura: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform vec2 u_Screen_Size;
  uniform float Time;
  //
  
  #define S(a,b,c) smoothstep(a,b,c)
  #define sat(a) clamp(a,0.0,1.0)
  
  // Borrowed from BigWIngs
  vec4 N14(float t) {
      return fract(sin(t*vec4(123., 104., 145., 24.))*vec4(657., 345., 879., 154.));
  }
  
  // Computes the RGB and alpha of a single flower in its own UV space
  vec4 sakura(vec2 uv, vec2 id, float blur)
  {
      float time = Time + 45.0; //time is offset to avoid the flowers to be aligned at start
  
      vec4 rnd = N14(mod(id.x, 500.0) * 5.4 + mod(id.y, 500.0) * 13.67); //get 4 random numbersper flower
  
      // Offset the flower form the center in a random Lissajous pattern
      uv *= mix(0.75, 4.3, rnd.y);
      uv.x += sin(time * rnd.z * 0.3) * 0.6;
      uv.y += sin(time * rnd.w * 0.45) * 0.4;
  
  
      // Computes the angle of the flower with a random rotation speed
      float angle = atan(uv.y, uv.x) + rnd.x * 421.47 + Time * mix(-0.6, 0.6, rnd.x);
  
  
      // euclidean distance to the center of the flower
      float dist = length(uv);
  
      // Flower shaped distance function form the center
      float petal = 1.0 - abs(sin(angle * 2.5));
      float sqPetal = petal * petal;
      petal = mix(petal, sqPetal, 0.7);
      float petal2 = 1.0 - abs(sin(angle * 2.5 + 1.5));
      petal += petal2 * 0.2;
  
      float sakuraDist = dist + petal * 0.25;
  
  
      // Compute a blurry shadow mask.
      float shadowblur = 0.3;
      float shadow = S(0.5 + shadowblur, 0.5 - shadowblur, sakuraDist) * 0.4;
  
      //Computes the sharper mask of the flower
      float sakuraMask = S(0.5 + blur, 0.5 - blur, sakuraDist);
  
      // The flower has a pink hue and is lighter in the center
      vec3 sakuraCol = vec3(1.0, 0.6, 0.7);
      sakuraCol += (0.5 -  dist) * 0.2;
  
      // Computes the border mask of the flower
      vec3 outlineCol = vec3(1.0, 0.3, 0.3);
      float outlineMask = S(0.5 - blur, 0.5, sakuraDist + 0.045);
  
      // Defines a tiling polarspace for the pistil pattern
      float polarSpace = angle * 1.9098 + 0.5;
      float polarPistil = fract(polarSpace) - 0.5; // 12 / (2 * pi)
  
      // Round dot in the center
      outlineMask += S(0.035 + blur, 0.035 - blur, dist);
  
      float petalBlur = blur * 2.0;
      float pistilMask = S(0.12 + blur, 0.12, dist) * S(0.05, 0.05 + blur , dist);
  
      // Compute the pistil 'bars' in polar space
      float barW = 0.2 - dist * 0.7;
      float pistilBar = S(-barW, -barW + petalBlur, polarPistil) * S(barW + petalBlur, barW, polarPistil);
  
      // Compute the little dots in polar space
      float pistilDotLen = length(vec2(polarPistil * 0.10, dist) - vec2(0, 0.16)) * 9.0;
      float pistilDot = S(0.1 + petalBlur, 0.1 - petalBlur, pistilDotLen);
  
      //combines the middle an border color
      outlineMask += pistilMask * pistilBar + pistilDot;
      sakuraCol = mix(sakuraCol, outlineCol, sat(outlineMask) * 0.5);
  
      //sets the background to the shadow color
      sakuraCol = mix(vec3(0.2, 0.2, 0.2) * shadow, sakuraCol, sakuraMask);
  
      //incorporates the shadow mask into alpha channel
      sakuraMask = sat(sakuraMask + shadow);
  
      //returns the flower in pre-multiplied rgba
      return vec4(sakuraCol, sakuraMask);
  }
  
  // blends a pre-multiplied src onto a dst color (without alpha)
  vec3 premulMix(vec4 src, vec3 dst)
  {
      return dst.rgb * (1.0 - src.a) + src.rgb;
  }
  
  // blends a pre-multiplied src onto a dst color (with alpha)
  vec4 premulMix(vec4 src, vec4 dst)
  {
      vec4 res;
      res.rgb = premulMix(src, dst.rgb);
      res.a = 1.0 - (1.0 - src.a) * (1.0 - dst.a);
      return res;
  }
  
  vec2 hash( vec2 p )
  {
      p = vec2( dot(p,vec2(127.1,311.7)), dot(p,vec2(269.5,183.3)) );
      return fract(sin(p)*43758.5453);
  }
  // Computes a Layer of flowers
  vec4 layer(vec2 uv, float blur)
  {
      vec4 accum = vec4(0.0);
      vec2 cellUV = fract(uv) - 0.5;
      vec2 cellId = floor(uv);
  
  
      // the flowers can overlap on the 9 neighboring cells so we blend them all together on each cell
      for (float y = -1.0; y <= 1.0; y++)
      {
          for (float x = -1.0; x <= 1.0; x++)
          {
              vec2 offset = vec2(x, y);
              vec4 sakura = sakura(cellUV - offset, cellId + offset, blur);
              accum = premulMix(sakura, accum);
          }
      }
  
      return accum;
  }
  
  
  void main()
  {
      vec2 TexCoord = TexCoord * u_Screen_Size;
      // Normalized pixel coordinates (from 0 to 1)
      vec2 nominalUV = TexCoord/u_Screen_Size.xy;
  
      vec2 uv = nominalUV - 0.5;
      uv.x *= u_Screen_Size.x / u_Screen_Size.y;
  
      // Scroll the UV with a cosine oscillation
      uv.y += Time * 0.1;
      uv.x -= Time * 0.03 + (sin(Time) / 2.0 + 1.0) * 0.1;
  
      uv *= 8.;
  
      //Compute a BG gradient
      float screenY = nominalUV.y;
      vec3 col = texture2D(ourTexture, nominalUV).xyz;
  
      // Compute a tilt-shift-like blur factor
      float blur = abs(nominalUV.y - 0.5) * 2.0;
      blur *= blur * 0.15;
  
      // Computes several layers with various degrees of blur and scale
      vec4 layer1 = layer(uv, 0.015 + blur);
  
      // Blend it all together
      col = premulMix(layer1, col);
  
  
      // Output to screen
      gl_FragColor = vec4(col,1.0);
  }`,
};

const videoVertexShader = `
      //浮点数设置为中等精度
      precision mediump float;
      attribute vec2 a_Position;
      attribute vec2 a_TexCoord;
      varying vec2 TexCoord;
      uniform vec2 u_Screen_Size;

      void main(){
          // vec2 position = (a_Position / u_Screen_Size) * 2.0 - 1.0;
          vec2 position = a_Position;
          // position = position * vec2(1.0, -1.0);
          gl_Position = vec4(position, 0, 1);
          TexCoord = a_TexCoord;
      }
      `;
const dynamicFilterConfig = [
  {
    id: "filter_default",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.default,
  },
  {
    id: "filter1",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.winterSnow,
  },

  {
    id: "filter2",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.waterDropletsFall,
  },
  {
    id: "filter3",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.burr,
  },
  {
    id: "filter4",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.outOfBody,
  },
  {
    id: "filter5",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.sun,
  },
  {
    id: "filter6",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.scale,
  },
  {
    id: "filter7",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.sakura,
  },
  {
    id: "filter8",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.jitterOffset,
  },
  {
    id: "filter9",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.ripplesInRain,
  },

  {
    id: "filter10",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.jellyRhythm,
  },
  {
    id: "filter11",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.smoke,
  },
  {
    id: "filter12",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.dynamicGrid,
  },

  {
    id: "filter13",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.blackAndWhiteFinale,
  },
  {
    id: "filter14",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.flashOfWhite,
  },
  {
    id: "filter15",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.senseOfColorChange,
  },
  {
    id: "filter16",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.motionBlur,
  },
  {
    id: "filter17",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.illusion,
  },
  {
    id: "filter18",
    vertexShader: videoVertexShader,
    fragmentShader: videoShader.spinningRound,
  },
  // {
  //   id: "filter17",
  //   vertexShader: videoVertexShader,
  //   fragmentShader: videoShader.starBulinbulin,
  // },
  // {
  //   id: "filter18",
  //   vertexShader: videoVertexShader,
  //   fragmentShader: videoShader.separationShift,
  // },
];
export { dynamicFilterConfig };
