<template>
  <div>
    <a-modal v-model:visible="innerVisible" :footer="null" width="198px" height="54px" :closable="null"
      :maskClosable="false" wrapClassName="downloadLoading-container">
      <div class="icon-loading" :style="{ marginRight: '10px', color: '#875EFF' }">
        <LoadingOutlined />
      </div>
      <div class="close-btn">
        Downloading</div>
      <div @click="handleCloseModal">
        <img :src="messageClose" :style="{ marginLeft: '33.81px', cursor: 'pointer' }" />
      </div>
    </a-modal>
  </div>
</template>
<script setup>
import { ref, defineProps, watchEffect, defineEmits } from "vue";
import messageClose from '@/assets/message_close.svg'
import { LoadingOutlined } from '@ant-design/icons-vue';

const props = defineProps({
  visible: Boolean
})
const emits = defineEmits(["handleDownload"]);
const innerVisible = ref(false)
const modalWrap = ref('modalWrapStyle')
watchEffect(() => {
  innerVisible.value = props.visible
})


const handleCloseModal = () => {
  innerVisible.value = false
  emits("handleDownload", false);
}
</script>
<style lang="less" scoped>
// .downloadLoading-container {
:global(.downloadLoading-container .ant-modal-content) {
  border-radius: 4px;
}

:global(.downloadLoading-container .ant-modal-body) {
  padding: 0;
  box-shadow: 0px 4px 8px rgba(31, 35, 41, 0.1);
  border-radius: 4px;

  .close-btn {
    margin-bottom: 0 !important;
    width: 198px;
    height: 54px;
    background-color: #F2EDFF;
    border: 1px solid #875EFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(31, 35, 41, 0.1);
    border-radius: 4px;
  }
}



// }

:global(.downloadLoading-container.ant-modal-wrap) {
  position: fixed;
  bottom: 0;
  top: 70%;
  right: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
</style>