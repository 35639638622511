<template>
  <div>
    <!-- 模型加载loading -->
    <model-status v-if="modelLoading || modelInitializing" :modelLoading="modelLoading"
      :modelInitializing="modelInitializing"></model-status>
    <div class="back-ground-remover-wrap" :style="{ 'padding-top': imageHandleComplete ? '48px' : '180px' }">
      <p class="tool-title" :style="{ 'margin-bottom': imageHandleComplete ? '30px' : '52px' }">
        Photo Animation
      </p>
      <!-- 上传组件 -->
      <div class="common-upload-box">
        <common-upload @changFileList="changFileList" @changSelectImage="changSelectImage" :imageUrls="imageUrls"
          :modelLoading="modelLoading" :modelInitializing="modelInitializing" :modelLoadingError="modelLoadingError"
          :webcamEnabled="webcamEnabled" :modelInProgress="modelInProgress" :imageHandleComplete="imageHandleComplete"
          :changeImage="changeImage" :uploadPlaceholder="uploadPlaceholder" />
      </div>

      <!-- 图片滤镜 -->
      <div class="imageFilter-container" :style="{
        opacity: imageHandleComplete ? 1 : 0,
        marginTop: imageHandleComplete ? '30px' : '-200px',
        pointerEvents: imageHandleComplete ? 'all' : 'none'
      }">
        <div class="canvas-show-container position-box" @mouseenter="handleMattingBoxMouseEnter"
          @mouseleave="handleMattingBoxMouseLeave">
          <canvas id="previewer"></canvas>
          <div class="buttons-container" v-if="showMattingEditBtns">
            <a-button type="primary" @click="downloadVideo()" :loading="loading">Download</a-button>
          </div>
        </div>
        <common-rate @changeRate="handleMattingChangeRate" :clearRate="clearRate" ref="rate" />
        <div class="imageFilter-preview-container">
          <ul class="canvas-show-ul" :style="{
            pointerEvents: loading || !imageHandleComplete ? 'none' : 'all',
          }">
            <li class="canvas-show-li" v-for="(filter, index) in imageUrlConfig" :key="filter.id" v-show="index != 0"
              @click="handlePreviewClick(filter.id, index)">
              <div class="canvas-show-hover" v-if="currentSelectItem === index"></div>
              <img :src="filter.imgUrl" width="76" :height="rightImageHeight" class="canvas-show-image" />
            </li>
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
          </ul>
          <canvas id="render" v-show="false"></canvas>
        </div>
      </div>
    </div>
    <DownloadLoading :visible="modalVisible" @handleDownload="handleStopDownload" />
  </div>
</template>


<script setup>
import ModelStatus from "../modelStatus.vue";
import { ref, onUnmounted } from "vue";
import CommonUpload from "../commonUpload.vue";
import { filterDownload } from "../../utils/buriedFunction/filter/filter-download.js";
import { filterEmoji } from "../../utils/buriedFunction/filter/filter-emoji.js";
import { filterPreview } from "../../utils/buriedFunction/filter/filter-preview.js";
import dynamicFilterPainter from "../../utils/webglRender/dynamicFilterPainter.js";
import { dynamicFilterConfig } from "../../utils/webglRender/videoFilter.js";
import CommonRate from "../common-rate.vue";
import animationIcon1 from '../../assets/example-images/animation_icon_1.webp'
import animationIcon2 from '../../assets/example-images/animation_icon_2.webp'
import animationIcon3 from '../../assets/example-images/animation_icon_3.webp'
import animationSrc1 from '../../assets/example-images/animation_src_1.webp'
import animationSrc2 from '../../assets/example-images/animation_src_2.webp'
import animationSrc3 from '../../assets/example-images/animation_src_3.webp'
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg/dist/ffmpeg.min.js';
import DownloadLoading from '../downloadLoading.vue'


let ffmpeg = createFFmpeg({ log: true })

// if (process.env.NODE_ENV === 'development') {
//   ffmpeg = createFFmpeg({ log: true })
// } else {
//   const proxyUrl = '/wasm/ffmpeg/ffmpeg-core.js';
//   ffmpeg = createFFmpeg({log: true })
// }

var md5 = require("md5");

const imageUrls = ref([
  {
    id: 1,
    iconSrc: animationIcon1,
    src: animationSrc1,
  },
  {
    id: 2,
    iconSrc: animationIcon2,
    src: animationSrc2,
  }, {
    id: 3,
    iconSrc: animationIcon3,
    src: animationSrc3,
  }
])
const modalVisible = ref(false)
const uploadPlaceholder = ref('')
const currentImgUrl = ref("");
const currentMattingSelectRate = ref({});
const imageHandleComplete = ref(false);
const showMattingEditBtns = ref(false);
const canvasWidth = ref(522);
const canvasHeight = ref(522);
const imgMultiple = ref(1);
const modelLoading = ref(false);
const changeImage = ref(false);
const modelInProgress = ref('start');
const currentSelectItem = ref(0);
const imgUrl = ref(null);
const rate = ref(null);
const imageUrlConfig = ref(dynamicFilterConfig);
const canvasNum = ref(0);
const loading = ref(false);
const currentFilterId = ref(-1);
const clearRate = ref(false)
const rightImageHeight = ref(76);
const mediaRecord = ref(null)

onUnmounted(() => {
  //卸载组件,清空wengl缓存
  //TODO 优化方案待优化
  cancelAnimationFrame(window.myReq)
  dynamicFilterPainter(
    "#previewer",
    0,
    0,
    dynamicFilterConfig[7].vertexShader,
    dynamicFilterConfig[7].fragmentShader,
    currentImgUrl.value,
    true,
    webglLoadDone,
    false,
    true
  );
})
const renderLiCanvas = (dynamicFilterConfig, url, index) => {
  if (!dynamicFilterConfig || !dynamicFilterConfig.length) {
    return;
  }

  //在渲染右侧 预览的时候去分批次加载所有的滤镜，加载完毕后截图，删除这个webgl上下文
  const filter = dynamicFilterConfig[index];
  console.log(`[ 准备开始${filter.id}的渲染任务] >`);
  dynamicFilterPainter(
    `#${filter.id}`,
    76,
    76,
    filter.vertexShader,
    filter.fragmentShader,
    url,
    false,
    webglLoadDone,
    true,
    false
  );
};

const changSelectImage = (url) => {
  modelInProgress.value = 'progressing';
  currentImgUrl.value = url;
  // drowAfterLoadImage(url);
  renderLiCanvas(dynamicFilterConfig, url, 0);
  if (imageHandleComplete.value) {
    imageHandleComplete.value = false;
  }
};

const handlePreviewClick = (id, index) => {
  // clearMainCanvas();
  filterPreview("toolkits_animation_preview", id);
  currentSelectItem.value = index;
  drowAfterLoadImage(currentImgUrl.value, index + 1, false, false);
};

const changFileList = (fileList) => {
  if (!fileList || !fileList.length) {
    return;
  }
  modelInProgress.value = 'progressing';
  const imgUrl = URL.createObjectURL(
    fileList[fileList.length - 1].originFileObj
  );
  currentSelectItem.value = 0
  clearRate.value = true
  rate.value.handleClearRate()
  currentImgUrl.value = imgUrl;
  canvasNum.value = 0;
  // drowAfterLoadImage(imgUrl);
  renderLiCanvas(dynamicFilterConfig, imgUrl, 0);
  if (imageHandleComplete.value) {
    changeImage.value = true;
    imageHandleComplete.value = false;
  }
};

function record (canvas, time) {

  var stream = canvas.captureStream(25 /*fps*/);
  let params = {}

  if (MediaRecorder.isTypeSupported("video/webm; codecs=h264")) {
    //chrome 
    params = {
      mimeType: "video/webm; codecs=h264",
    }
  } else if (MediaRecorder.isTypeSupported("video/mp4;  codecs=avc1")) {
    //safari 
    params = {
      mimeType: "video/mp4;  codecs=avc1",
    }
  }

  var recordedChunks = [];
  //通过使用MediaRecorder.isTypeSupported() 判断当前浏览器使用不同的编码格式
  //safari video/mp4;  codecs=avc1
  //chrome video/webm; codecs=h264
  const mediaRecorder = new MediaRecorder(stream, params);
  mediaRecord.value = mediaRecorder;
  mediaRecorder.start(time || 5500);
  mediaRecorder.ondataavailable = function (event) {
    recordedChunks.push(event.data);
    // if (mediaRecorder.state === "recording") {
    //   mediaRecorder.stop();
    // }
  };

  mediaRecorder.onstop = async function () {
    var blob = new Blob(recordedChunks, { type: 'video/webm;codecs=h264' });
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }

    ffmpeg.FS("writeFile", "test.webm", await fetchFile(blob));
    await ffmpeg.run("-i", "test.webm", "-c:v", "copy", "-an", "test.mp4");
    const data = ffmpeg.FS("readFile", "test.mp4");
    const dataUrl = URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }));
    loading.value = false;
    var link = document.createElement('a')
    link.href = dataUrl
    link.download = `photo-animation-${md5(Date.now())}.mp4`
    link.click()
    modalVisible.value = false
  };

  setTimeout(() => {
    mediaRecorder.stop();
    mediaRecorder.value = null
  }, 6000);
}

function downloadVideo () {
  loading.value = true;
  modalVisible.value = true;
  if (currentFilterId.value) {
    const filterId = `filter${currentFilterId.value - 1}`;
    filterDownload("toolkits_animation_download", filterId);
  }
  const canvas = document.getElementById("previewer");
  record(canvas, 5500);
}

const handleStopDownload = () => {
  // 停止下载
  if (mediaRecord.value && mediaRecord.value.state != "inactive") {
    mediaRecord.value.stop();
    modalVisible.value = false
    loading.value = false
  }
}
const handleMattingChangeRate = (item) => {
  if (currentFilterId.value) {
    const filterId = `filter${currentFilterId.value - 1}`;
    filterEmoji("toolkits_animation_emoji", item.id, filterId);
  }
  currentMattingSelectRate.value = item;
};


const handleMattingBoxMouseEnter = () => {
  showMattingEditBtns.value = true;
};

const handleMattingBoxMouseLeave = () => {
  showMattingEditBtns.value = false;
};

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const getImageFromCanvas = async (filterId, gl, texture, buffer, indicesBuffer) => {
  const canvas = document.getElementById("render");
  await timeout(50);
  const dataURL = canvas.toDataURL();
  //怎么
  gl.deleteTexture(texture)
  gl.deleteBuffer(buffer);
  gl.deleteBuffer(indicesBuffer);
  // gl.getExtension('WEBGL_lose_context').loseContext();
  imageUrlConfig.value.forEach((filter) => {
    if (filterId == filter.id) {
      filter.imgUrl = dataURL;
      // document.getElementById(filterId).remove();
      console.log(`[ 删除${filterId}的上下文，开始渲染下一个]>`);
    }
  });
};

const webglLoadDone = async (id, gl, texture, buffer, indicesBuffer) => {
  console.log(`[ 完成${id}的渲染任务，开始截图 ] >`);
  const filterId = id.replace("#", "");
  //截图 删除上下文
  await getImageFromCanvas(filterId, gl, texture);
  if (canvasNum.value == dynamicFilterConfig.length - 1) {
    console.log("全部加载完毕");
    drowAfterLoadImage(currentImgUrl.value);
    imageHandleComplete.value = true;
    modelInProgress.value = 'done';
    uploadPlaceholder.value = 'Change an image here'
    return;
  }
  const index = canvasNum.value;
  setTimeout(() => {
    renderLiCanvas(dynamicFilterConfig, currentImgUrl.value, index + 1);
  }, 0);

  canvasNum.value += 1;
};

const drowAfterLoadImage = (url, index, isClear, isRenderList) => {
  let img = new Image();
  const filterIndex = index ? index - 1 : 0;
  const clear = isClear === false ? false : true;
  img.onload = function () {
    const { imgWidth, imgHeight } = showImage(img);

    console.log('imgWidth, imgHeight', img.width, img.height, imgWidth, imgHeight)
    canvasWidth.value = imgWidth;
    canvasHeight.value = imgHeight;
    currentImgUrl.value = url;
    rightImageHeight.value = (imgHeight * 76) / imgWidth
    currentFilterId.value = index;
    dynamicFilterPainter(
      "#previewer",
      imgWidth,
      imgHeight,
      dynamicFilterConfig[filterIndex].vertexShader,
      dynamicFilterConfig[filterIndex].fragmentShader,
      url,
      clear,
      webglLoadDone,
      false,
      false
    );
  };
  img.src = url;
};

// 图片缩放
const showImage = (image) => {
  if (image.width > 522 || image.height > 522) {
    // 长 > 宽
    if (522 / image.width > 522 / image.height) {
      if (522 / image.width < 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.height;
      } else if (522 / image.width > 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.height;
      }
    } else if (522 / image.width < 522 / image.height) {
      // 宽 > 长
      if (522 / image.width < 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.width;
      } else if (522 / image.width < 1 && 522 / image.height > 1) {
        imgMultiple.value = 522 / image.width;
      }
    } else {
      imgMultiple.value = 522 / image.width;
    }
  } else if (image.width < 522 && image.height < 522) {
    if (522 / image.width > 522 / image.height) {
      imgMultiple.value = 522 / image.height;
    } else if (522 / image.width < 522 / image.height) {
      imgMultiple.value = 522 / image.width;
    } else {
      imgMultiple.value = 1;
    }
  }
  const imgWidth = imgMultiple.value * image.width;
  const imgHeight = imgMultiple.value * image.height;
  const imgX = (522 - imgWidth) / 2;
  const imgY = (522 - imgHeight) / 2;

  return {
    imgX,
    imgY,
    imgWidth,
    imgHeight,
  };
};
</script>

<style lang="less" scoped>
.backGroundRemover-container {
  width: 100%;
  height: 452px;
  margin-top: 82px;
  display: flex;
  justify-content: center;
}

.back-ground-remover-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  margin-right: 118px;
}

.tool-title {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  margin-bottom: 52px;
}

.imageFilter-container {
  margin-top: 48px;
  width: 100%;
  position: relative;
}

.common-upload-box {
  width: 604px;
  height: 92px;
  border: 2px dashed #878787;
  border-radius: 6px;
}

.canvas-show-hover {
  position: absolute;
  width: 76px;
  height: 76px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.45);
}

.canvas-show-container {
  margin: 0 auto;
  text-align: center;
  background: #f7f7f7;
  width: 522px;
  height: 522px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageFilter-preview-container {
  position: absolute;
  top: 0px;
  right: 100px;
}

.canvas-show-ul {
  list-style-type: none;
  width: 320px;
  height: 522px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.canvas-show-ul::-webkit-scrollbar {
  background-color: transparent;
  /* or add it to the track */
  width: 7px;
}

.canvas-show-ul::-webkit-scrollbar-thumb {
  background: rgba(31, 35, 41, 0.3);
  border-radius: 3.5px;
  width: 7px !important;
  height: 135px !important;
}

.canvas-show-ul::-webkit-scrollbar-track {
  background: transparent;
}

.canvas-show-li {
  width: 76px;
  height: 76px;
  background: #ededed;
  border-radius: 6px;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center
}



.canvas-show-li:nth-last-of-type(-n+3) {
  margin-bottom: 0;
}

.canvas-show-image {
  -webkit-user-drag: none;
}

.canvas-show-li:hover {
  cursor: pointer;
}

.canvas-show-li canvas {
  border-radius: 4px;
}

.placeholder-box {
  width: 76px;
  display: inline-block;
  height: 1px;
  margin-right: 12px;
}

.position-box {
  position: relative;

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 80px 14px 80px;
    display: flex;
    justify-content: center;


    .ant-btn-primary {
      border-color: #875eff;
    }

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border-color: #875eff;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      border-color: #875eff;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}
</style>
